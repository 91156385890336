.repo-card-div {
  color: rgb(88, 96, 105);
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  flex: 1 1 25%;
}
.repo-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-sector-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.repo-sector {
  text-align: left;
  color: black !important;
  font-family: "Google Sans Regular" !important;
  margin: 0.75rem;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 6px;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}
.repo-creation-date subTitle {
  width: 5px;
}
.repo-languages {
  display: flex;
  justify-content: flex-start;
}
.repo-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 1rem; /* Reduced padding */
  display: flex; /* Added display flex to use flex properties */
  flex-direction: column; /* Added flex direction to arrange elements vertically */
}

.repo-avatar-div {
  flex: 1; /* Take up half of the card's height */
}

.repo-avatar {
  width: 100%; /* Take up full width of the card */
  height: 100%; /* Take up full height of the avatar div */
  border-radius: 8px; /* Rounded corners */
  object-fit: cover;
}
.repo-avatar:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.repo-details {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column; /* Arrange elements vertically */
  justify-content: space-between; /* Space out elements */
  margin-top: 0.5rem; /* Add some spacing at the top */
}

/* Rest of the CSS remains the same */

/* 
.repo-avatar-div {
  text-align: center;
  margin-bottom: 1rem;
} */

/* .repo-avatar {
  width: 100%;
  height: 60%;
  object-fit: cover;
} */
.repo-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.view-project-button,
.source-code-button {
  background-color: #007bff;
  border-radius: 6px;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.view-project-button:hover,
.source-code-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .repo-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .repo-card-div {
    flex: 1 1 100%;
  }

  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}
